import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import TopbarSearchForm from '../../../../components/Topbar/TopbarSearchForm/TopbarSearchForm';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../../util/search';
import { useConfiguration } from '../../../../context/configurationContext';
import { parse } from '../../../../util/urlHelpers';
import { FormattedMessage } from 'react-intl';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../../../util/routes';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefaultComponent = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;

  const classes = classNames(rootClassName, css.root || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const location = typeof window !== 'undefined' ? window.location : {};
  const url = location.search ? location.search : ""

  const { keywords, address, origin, bounds } = parse(url, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const topbarSearcInitialValues = () => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
        : null,
    };
  };
  const initialSearchFormValues = topbarSearcInitialValues();

  // Search submit
  const handleSubmit = values => {
    // const { currentSearchParams } = this.props;
    const { history } = props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      // ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const styleTitle = blockId === 'custom-hero-with-search-field' ? css.textTitleCenter : null;
  const styleDescription =
    blockId === 'custom-hero-with-search-field' ? css.textDescriptionCenter : null;

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} className={styleTitle} />
          <Field data={text} options={options} className={styleDescription} />
          {blockId === 'custom-hero-with-search-field' && (
            <TopbarSearchForm
              appConfig={config}
              initialValues={initialSearchFormValues}
              showIcon={false}
              onSubmit={handleSubmit}
              className={css.searchCss}
              isMobile
            >
              <p className={css.mobileHelp}>
                <FormattedMessage id="Topbar.mobileSearchHelp" />
              </p>
            </TopbarSearchForm>
          )}

          {blockId === 'custom-hero-with-search-field' ? (
            <div className={css.linkPosition}>
              <Field data={callToAction} options={options} />
            </div>
          ) : (
            <Field data={callToAction} className={ctaButtonClass} options={options} />
          )}
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefaultComponent.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefaultComponent.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const BlockDefault = compose(withRouter)(BlockDefaultComponent);

export default BlockDefault;
