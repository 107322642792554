import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';
import TopbarSearchForm from '../../../../components/Topbar/TopbarSearchForm/TopbarSearchForm';
import { useConfiguration } from '../../../../context/configurationContext';
import { isMainSearchTypeKeywords } from '../../../../util/search';
import { parse } from 'url';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHeroComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const location = typeof window !== 'undefined' ? window.location : {};
  const url = location.search ? location.search : ""

  const { keywords, address, origin, bounds } = parse(url, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const topbarSearcInitialValues = () => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
        : null,
    };
  };
  const initialSearchFormValues = topbarSearcInitialValues();

  // Search submit
  const handleSubmit = values => {
    // const { currentSearchParams } = this.props;
    const { history } = props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      // ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const useSearchCss = sectionId.includes('custom-hero-with-search-field')
    ? css.searchContainer
    : rootClassName || css.root;

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(useSearchCss)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field
            data={title}
            className={
              sectionId.includes('custom-hero-with-search-field')
                ? css.textTitleCenter
                : defaultClasses.title
            }
            options={fieldOptions}
          />
          <Field
            data={description}
            className={
              sectionId.includes('custom-hero-with-search-field')
                ? css.textDescriptionCenter
                : defaultClasses.description
            }
            options={fieldOptions}
          />
          {sectionId.includes('custom-hero-with-search-field') && (
            <TopbarSearchForm
              appConfig={config}
              initialValues={initialSearchFormValues}
              showIcon={false}
              onSubmit={handleSubmit}
              className={css.searchCss}
              isMobile
            >
              <p className={css.mobileHelp}>
                <FormattedMessage id="Topbar.mobileSearchHelp" />
              </p>
            </TopbarSearchForm>
          )}
          {sectionId.includes('custom-hero-with-search-field') ? (
            <Field data={callToAction} className={css.linkPosition} options={fieldOptions} />
          ) : (
            <Field
              data={callToAction}
              className={defaultClasses.ctaButton}
              options={fieldOptions}
            />
          )}
        </header>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHeroComponent.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHeroComponent.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SectionHero = compose(withRouter)(SectionHeroComponent);

export default SectionHero;
